<template>
  <v-card
    id="ProfileForm"
    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 100% !important' : ''"
    elevation="6"
    class="mx-0 px-0"
  >
    <v-tabs v-model="tab">
      <v-row v-if="$vuetify.breakpoint.xsOnly">
        <v-col class="pt-0">
          <v-select
            :items="tabs"
            item-text="text"
            item-value="href"
            v-model="tab"
            outlined
            dense
            style="height: 20px"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-tab
          href="#profile"
          class="pa-0 ma-0"
          v-if="!$store.getters['auth/isAdmin']"
          active-class="color"
          >{{
            $store.getters["auth/isStudio"]
              ? $tc("studio", 1)
              : $tc("artist", 1)
          }}</v-tab
        >
        <v-tab active-class="color" href="#contact">{{
          $t("info_contact")
        }}</v-tab>
        <v-tab href="#security" active-class="color">{{
          $t("change", { name: $t("password") })
        }}</v-tab>
      </v-row>
    </v-tabs>
    <form
      action
      data-vv-scope="profile-form"
      @submit.prevent="validateForm('profile-form')"
      v-if="user"
    >
      <v-tabs-items v-model="tab">
        <v-tab-item value="profile">
          <v-row
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'padding-left: 12px !important; padding-right: 12px !important'
                : ''
            "
          >
            <v-col cols="12" md="7">
              <!--<v-card elevation="6">
                  <v-card-title>
                    <v-row>
                      <v-col cols="9" md="10">Perfil</v-col>
                      <v-col>
                        <ProfileAvatar />
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <div>
                      <label v-text="$t('first_name')"></label>

                      <v-row>
                        <v-col>
                          <v-text-field
                            outlined
                            dense
                            v-model="user.first_name"
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-text-field
                            outlined
                            dense
                            v-model="user.last_name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>
                    <div v-if="$store.getters['auth/isTattooer']">
                      <label v-text="$t('tattooers.gender')"></label>

                      <v-row>
                        <v-col>
                          <v-select
                            :items="genders"
                            v-model="user.tattooer.gender"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>
                  </v-card-text>
                </v-card>-->
              <div elevation="6" v-if="$store.getters['auth/isStudio']">
                <v-row>
                  <v-col cols="12" md="7" class="py-0 "
                    ><label>{{ $t("studios.name") }}</label>
                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      hide-details
                      dense
                      v-model="user.studio.studio_name"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="12" md="5" class="py-0"
                    ><label>{{
                      $t("consent-form.sanitary_auth_number")
                    }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      dense
                      hide-details
                      v-model="user.studio.sanitary_auth"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="12" class="py-0"
                    ><label>{{ $t("studios.address") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      dense
                      v-model="user.studio.address"
                      hide-details
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row v-if="!$vuetify.breakpoint.mdAndDown">
                  <v-col cols="2" class="py-0"
                    ><label>{{ $t("studios.number") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      v-mask="'#####'"
                      v-model="user.studio.number"
                      dense
                      type="number"
                      v-on:keypress="isNumber($event)"
                      hide-details
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="2" class="py-0"
                    ><label>{{ $t("studios.floor") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      v-mask="'XXXXX'"
                      v-model="user.studio.piso"
                      dense
                      hide-details
                    >
                    </v-text-field></v-col
                  ><v-col cols="2" class="py-0"
                    ><label>{{ $t("studios.door") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      v-mask="'XXXXX'"
                      v-model="user.studio.puerta"
                      dense
                      hide-details
                    >
                    </v-text-field></v-col
                  ><v-col cols="3" class="py-0"
                    ><label>{{ $t("studios.postal_code") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      v-mask="'#####'"
                      dense
                      v-model="user.studio.postal_code"
                      hide-details
                    >
                    </v-text-field></v-col
                  ><v-col cols="3" class="py-0"
                    ><label>{{ $t("studios.city") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      dense
                      hide-details
                      v-model="user.studio.city"
                    >
                    </v-text-field></v-col
                ></v-row>

                <v-row v-if="$vuetify.breakpoint.mdAndDown">
                  <v-col cols="4" class="py-0">
                    <label>{{ $t("studios.number") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      type="number"
                      v-on:keypress="isNumber($event)"
                      dense
                      hide-details
                      v-mask="'#####'"
                      v-model="user.studio.number"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="4" class="py-0"
                    ><label>{{ $t("studios.floor") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      dense
                      v-mask="'XXXXX'"
                      hide-details
                      v-model="user.studio.piso"
                    >
                    </v-text-field></v-col
                  ><v-col cols="" class="py-0"
                    ><label>{{ $t("studios.door") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      dense
                      v-mask="'XXXXX'"
                      hide-details
                      v-model="user.studio.puerta"
                    >
                    </v-text-field></v-col
                ></v-row>
                <v-row v-if="$vuetify.breakpoint.mdAndDown">
                  <v-col
                    cols="5"
                    v-if="$vuetify.breakpoint.mdAndDown"
                    class="py-0"
                    ><label>{{ $t("studios.postal_code") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      dense
                      v-mask="'#####'"
                      hide-details
                      v-model="user.studio.postal_code"
                    >
                    </v-text-field></v-col
                  ><v-col
                    cols="5"
                    class="py-0"
                    v-if="$vuetify.breakpoint.mdAndDown"
                    ><label>{{ $t("studios.city") }}</label>

                    <v-text-field
                      :disabled="edit == false"
                      outlined
                      dense
                      hide-details
                      v-model="user.studio.city"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col class="py-1" cols="12" md="5">
                    <label>
                      {{ $tc("service", 2) }}
                    </label>
                    <v-select
                      :items="items"
                      outlined
                      dense
                      :disabled="edit == false"
                      multiple
                      v-model="services"
                      hide-details
                      :item-text="(x) => $tc(x.name)"
                      return-object
                      data-vv-name="artist-type"
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index <= 0">{{ $tc(item.name) }}</span>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption ml-1"
                        >
                          (+{{ services.length - 1 }} más)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <div>
                  <v-row>
                    <v-col class="py-1" cols="12" md="5">
                      <label
                        class="mb-3"
                        v-text="$t('consent-form.studio_signature')"
                      ></label>
                      <signatureArtist />
                    </v-col>
                  </v-row>
                </div>
              </div>

              <div elevation="6" v-if="$store.getters['auth/isTattooer']">
                <v-card-title
                  v-text="$t('information', { name: $tc('artist') })"
                />
                <v-card-text>
                  <div v-if="isTattooer">
                    <v-row>
                      <v-col cols="7" md="4">
                        <label v-text="$t('tattooers.tattoo_type')"></label>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="5" md="4" xl="3">
                        <TattooTypes
                          :tattooer_id="$store.state.auth.user.id"
                        ></TattooTypes>
                      </v-col>
                    </v-row>
                    <v-divider />
                  </div>
                  <!-- <div>
                      <v-row>
                        <v-col cols="6" md="6">
                          <label
                            v-text="
                              $t('timetable', {
                                name: $vuetify.breakpoint.xs
                                  ? ''
                                  : $tc('artist'),
                              })
                            "
                          ></label>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="5" md="4" xl="3">
                          <timetable :id="user.id" disabled></timetable>
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>
                    <div>
                      <v-row>
                        <v-col cols="7" md="4">
                          <label v-text="$t('tattooers.token_payment')"></label>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="5" md="4" xl="3">
                          <token-payments
                            :id="user.id"
                            disabled
                          ></token-payments>
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>
                    -->

                  <div>
                    <v-row v-if="$store.getters['auth/isTattooerMain']">
                      <v-col cols="7" md="4">
                        <label v-text="$tc('tattooers.studio')"></label>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="5" md="4" xl="3"><add-studio /></v-col>
                    </v-row>
                    <v-divider />
                  </div>

                  <div>
                    <v-row>
                      <v-col cols="7" md="4">
                        <label v-text="$tc('vacations.title')"></label>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="5" md="4" xl="3"
                        ><v-btn
                          @click="alertVacation"
                          style="height: 30px"
                          v-on="on"
                          elevation="0"
                          block
                          v-text="$t('edit', { name: '' })"
                      /></v-col>
                    </v-row>
                    <v-divider />
                  </div>
                  <div>
                    <v-row>
                      <v-col cols="7" md="4">
                        <label
                          v-text="$t('consent-form.tattooer_signature')"
                        ></label>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="5" md="4" xl="3">
                        <signatureArtist />
                      </v-col>
                    </v-row>
                    <v-divider />
                  </div>
                  <div>
                    <v-row>
                      <v-col cols="7" md="4">
                        <label v-text="$t('Servicios')"></label>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="5" md="4" xl="3"><services /> </v-col>
                    </v-row>
                  </div>
                  <!--
                    <div>
                      <v-row>
                        <v-col cols="7" md="4">
                          <label
                            v-text="$t('tattooers.reservation_system')"
                          ></label>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="5" md="4" xl="3">
                          <LaborExange disabled></LaborExange>
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>
                    -->
                </v-card-text>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="4">
              <label
                style="
                    font-size: 12px;
                    margin-bottom: 12px;
                    text-align: center;
                  "
                >{{ $t("studios.logo") }}</label
              >
              <ProfileAvatar :edit="edit"> </ProfileAvatar>

              <v-row class="pa-0" justify="center">
                <v-col class="pb-0" :cols="edit == true ? '12' : '6'">
                  <v-row v-if="edit == true" class="pa-0">
                    <v-col cols="6" class="py-0"
                      ><v-btn
                        @click="changeEdit()"
                        block
                        outlined
                        style="height: 30px !important; color: #363533"
                        elevation="0"
                      >
                        {{ $t("cancel", { name: $t("") }) }}</v-btn
                      ></v-col
                    >
                    <v-col cols="6" class="py-0"
                      ><v-btn
                        @click="
                          saveProfile();
                          changeEdit();
                        "
                        block
                        style="height: 30px !important; color: #363533"
                        elevation="0"
                      >
                        <v-icon style="margin-right: 5px" size="14px"
                          >$save</v-icon
                        >{{ $t("save", { name: $t("") }) }}</v-btn
                      ></v-col
                    >
                  </v-row>
                  <v-btn
                    v-else
                    @click="edit = true"
                    block
                    style="height: 30px !important; color: #363533"
                    elevation="0"
                  >
                    <v-icon style="margin-right: 5px" size="14px">$edit</v-icon
                    >{{ $t("edit", { name: $t("") }) }}</v-btn
                  >
                </v-col>
              </v-row>

              <!-- v-if documento adjuntado-->
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="contact">
          <v-row
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'padding-left: 12px !important; padding-right: 12px !important'
                : ''
            "
          >
            <v-col cols="12" md="3" class="py-0">
              <div>
                <label>{{ $t("first_name") }}</label>
                <v-text-field
                  :disabled="edit2 == false"
                  outlined
                  hide-details
                  dense
                  v-model="user.first_name"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <div>
                <label>{{ $t("last_name") }}</label>
                <v-text-field
                  :disabled="edit2 == false"
                  outlined
                  dense
                  hide-details
                  v-model="user.last_name"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <div>
                <label v-text="$t('email')"></label>
                <v-text-field
                  :disabled="edit2 == false"
                  outlined
                  dense
                  hide-details
                  v-model="user.email"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'padding-left: 12px !important; padding-right: 12px !important'
                : ''
            "
          >
            <v-col cols="12" md="3" class="py-0">
              <div>
                <label>{{ $t("studios.birthdate") }}</label>
                <v-menu
                  ref="menu"
                  :disabled="edit2 == false"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      :disabled="edit2 == false"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      :value="
                        user.nacimiento
                          ? $d(new Date(user.nacimiento), 'date2digits')
                          : ''
                      "
                    >
                      <template v-slot:prepend-inner>
                        <!--Aqui va el v-icon -->
                        <v-icon
                          class="pt-0 pb-1"
                          style="margin-top: 2px"
                          :color="edit2 == false ? 'gris1' : 'primary'"
                          >$appointments</v-icon
                        >
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="user.nacimiento"
                    no-title
                    scrollable
                    hide-details
                    ref="picker"
                    :max="today"
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col cols="6" md="3" class="py-0">
              <div>
                <label>{{ $t("studios.nationality") }}</label>

                <CountrySelect
                  :value="user.studio"
                  valueKey="country"
                  v-if="$store.getters['auth/isStudio']"
                  :edit="edit2"
                >
                </CountrySelect>
                <CountrySelect
                  :value="user.tattooer"
                  valueKey="nationality"
                  v-else
                  :edit="edit2"
                >
                </CountrySelect>
              </div>
            </v-col>
            <v-col cols="6" md="3" class="pt-0">
              <div>
                <label v-text="$t('phone')"></label>
                <v-text-field
                  :disabled="edit2 == false"
                  outlined
                  dense
                  hide-details
                  type="number"
                  v-on:keypress="isNumber($event)"
                  v-model="user.phone"
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              v-if="!$vuetify.breakpoint.smAndDown"
              cols="6"
              md="3"
            ></v-col>
            <v-col
              v-if="$store.getters['auth/isTattooer']"
              cols="12"
              sm="6"
              md="3"
              class="pt-0"
            >
              <label v-text="$t('tattooers.artist_type')"></label>
              <v-select
                :items="items"
                outlined
                dense
                :disabled="edit2 == false"
                multiple
                v-model="user.types"
                hide-details
                item-value="id"
                :item-text="(x) => $tc(x.name)"
                data-vv-name="artist-type"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index <= 0">{{ $tc(item.name) }}</span>
                  <span v-if="index === 1" class="grey--text text-caption ml-1">
                    (+{{ user.types.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="$store.getters['auth/isTattooer']"
              cols="6"
              md="3"
              class="pt-0"
            >
              <div>
                <label v-text="$t('dni')"></label>
                <v-text-field
                  :disabled="edit2 == false"
                  outlined
                  hide-details
                  dense
                  v-model="user.tattooer.DNI"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="pl-0 ml-3"
              :style="edit == false ? ' padding-right: 43px !important;' : ''"
              cols="12"
              md="6"
            >
              <v-row class="pa-0">
                <template v-if="edit2 == true">
                  <v-col cols="5" md="6" class="py-0 ml-3 ml-md-0">
                    <v-btn
                      @click="changeEdit2()"
                      block
                      outlined
                      style="height: 30px !important; color: #363533"
                      elevation="0"
                    >
                      {{ $t("cancel", { name: $t("") }) }}</v-btn
                    ></v-col
                  >
                  <v-col cols="5" md="6" class="py-0"
                    ><v-btn
                      @click="
                        saveProfile();
                        changeEdit2();
                      "
                      block
                      style="height: 30px !important; color: #363533"
                      elevation="0"
                    >
                      <v-icon style="margin-right: 5px" size="14px"
                        >$save</v-icon
                      >{{ $t("save", { name: $t("") }) }}</v-btn
                    ></v-col
                  >
                </template>

                <v-col cols="4" class="py-0 ml-3 ml-md-0" v-else
                  ><v-btn
                    @click="edit2 = true"
                    block
                    style="height: 25px; color: #363533"
                    elevation="0"
                  >
                    <v-icon style="margin-right: 5px" size="14px">$edit</v-icon
                    >{{ $t("edit", { name: $t("") }) }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="security">
          <security />
        </v-tab-item>
      </v-tabs-items>
    </form>
  </v-card>
</template>

<script>
import * as moment from "moment";
import { mapState, mapActions } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "ProfileForm",
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    if (this.$store.getters["auth/isStudio"])
      this.services = JSON.parse(
        this.$store.getters["auth/getSetting"]("studio_services")
      );

    this.fetchTypes();
    if (this.user.types.length && typeof this.user.types[0] === "object")
      this.user.types = this.user.types.map((x) => x.id);
  },
  data() {
    return {
      edit: false,
      edit2: false,
      genders: [
        { text: this.$t("gender.m"), value: "m" },
        { text: this.$t("gender.f"), value: "f" },
      ],
      items: [],

      tab: "profile",
      menu: false,
      today: null,
      services: [],
    };
  },
  directives: {
    mask,
  },
  watch: {
    menu(val) {
      val &&
        setTimeout(() => {
          this.$refs.picker.activePicker = "YEAR";
        });
    },
  },
  computed: {
    isTattooer() {
      return this.$store.state.auth.user.tattooer.type_artist.find(
        (x) => x.id === 1 || x === 1
      );
    },
    tabs() {
      return [
        {
          href: "profile",
          text: this.$store.getters["auth/isStudio"]
            ? this.$tc("studio", 1)
            : this.$tc("artist", 1),
        },
        { href: "contact", text: this.$t("info_contact") },
        {
          href: "security",
          text: this.$t("change", { name: this.$t("password") }),
        },
      ];
    },
    user: {
      get() {
        return this.$store.state.auth.user;
      },
      set(value) {
        //console.log("setting");
        this.$store.commit("auth/UPDATE_USER", value);
      },
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    alertVacation() {
      this.$confirm(this.$t("vacations.alert"), "", "").then(() => {
        this.$router.push({
          name: "studioList",
        });
      });
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    ...mapActions("auth", ["profileUpdate"]),
    changeEdit() {
      this.edit = false;
      if (this.$store.getters["auth/isStudio"])
        this.services = JSON.parse(
          this.$store.getters["auth/getSetting"]("studio_services")
        );
      //console.log(this.edit);
    },
    changeEdit2() {
      this.edit2 = false;
      //console.log(this.edit);
    },
    ...mapActions("tattooers", ["getTypeArtist"]),
    fetchTypes() {
      this.getTypeArtist().then((tattooers) => {
        ////console.log(tattooers);
        this.items = tattooers.data;
      });
    },
    saveProfile() {
      let Form = new FormData();
      Object.keys(this.user).forEach((key) => {
        switch (key) {
          case "studio":
          case "tattooer":
            Object.keys(this.user[key]).forEach((key2) => {
              Form.append(`${key}[${key2}]`, this.user[key][key2]);
            });
            break;

          default:
            Form.append(key, this.user[key]);
            break;
        }
      });

      if (this.$store.getters["auth/isStudio"])
        this.settingUpdate({
          key: "studio_services",
          value: this.services,
          json: true,
        }).then((data) => {
          this.services = JSON.parse(
            this.$store.getters["auth/getSetting"]("studio_services")
          );
        });

      this.profileUpdate(Form).then((result) => {
        //console.log(result);
        this.$alert("¡Perfil guardado con éxito!", "", "");

        if (this.user.types.length && typeof this.user.types[0] === "object")
          this.user.types = this.user.types.map((x) => x.id);
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
  },
  components: {
    CountrySelect: () => import("@/components/ui/CountrySelect"),
    security: () => import("@/components/user/settings/Security"),
    ProfileAvatar: () => import("@/components/user/settings/profile/Image"),
    tokenPayments: () =>
      import("@/components/user/settings/profile/TokenPayment"),
    timetable: () => import("@/components/user/settings/profile/Timetable"),
    AddStudio: () => import("@/components/user/settings/profile/AddStudio"),
    TattooTypes: () => import("@/components/user/settings/profile/TattooTypes"),
    LaborExange: () => import("@/components/user/settings/profile/LaborExange"),
    PopUpFirma: () => import("@/components/ui/PopUpFirma"),
    signatureArtist: () => import("@/components/tattooer/SignatureArtist"),
    services: () =>
      import("@/components/user/settings/configuracion/services/Dialog"),
  },
};
</script>
<style lang="sass">
#ProfileForm

  label
    display: block
    height: 25px
    color: var(--v-primary-base) !important

.color
  color: var(--v-primary-base) !important

.inputMargin
  margin-top: -5px !important

.inputMargin > .v-input__control > .v-input__slot:before, .inputMargin > .v-input__control > .v-input__slot:after, .theme--dark.inputMargin > .v-input__control > .v-input__slot:before, .theme--dark.inputMargin > .v-input__control > .v-input__slot:after
  height: 1px
  background-color: var(--v-primary-base) !important
  border-color: var(--v-primary-base) !important

.tokenTable
  border-bottom: none !important

.colorBarrita
  //margin-left: -8px

.one-line
  white-space: nowrap !important

.colorBarrita > .v-input__control > .v-input__slot:before, .colorBarrita > .v-input__control > .v-input__slot:after
  height: 1px
  background-color: var(--v-primary-base) !important
  border-color: var(--v-primary-base) !important
</style>
